<!--  -->
<template>
  <div class="approvalList">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '固定资产管理',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="search-tab">
      <div class="tab-cont" @click="showDepartment = true">
        <p class="label">{{ departmentName }}</p>
        <p class="react"></p>
      </div>
      <div class="tab-cont" @click="showPrincipal = true">
        <p class="label">{{ principalName }}</p>
        <p class="react"></p>
      </div>
      <div class="tab-cont-icon" @click="clearOption">
        <p class="label"><van-icon name="delete-o" />筛选项</p>
      </div>
    </div>
    <div class="num-btn">
      <p class="num">共{{ total }}条数据</p>
      <p class="add" @click="addAssets">新增</p>
    </div>
    <van-popup v-model="showDepartment" position="bottom">
      <van-picker
        v-if="departmentList && departmentList.length"
        show-toolbar
        :columns="departmentList"
        @confirm="departmentConfirm"
        @cancel="showDepartment = false"
      />
      <van-empty v-else description="暂无部门" />
    </van-popup>
    <van-popup v-model="showPrincipal" position="bottom">
      <van-picker
        v-if="staffData && staffData.length"
        show-toolbar
        :columns="staffData"
        @confirm="staffConfirm"
        @cancel="showPrincipal = false"
      />
      <van-empty v-else description="暂无工作人员" />
    </van-popup>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="handleLoad"
      v-if="list && list.length"
    >
      <ul class="approval-ul">
        <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
          <assets-item :info="item" :type="queryParams.type" />
        </li>
      </ul>
    </van-list>
    <van-empty v-else description="暂无数据" :image-size="60" />
  </div>
</template>

<script>
import { queryFixedAssetsList } from "@/api/fixedAssets/index.js";
import { getAllStaffList } from "@/api/staff/index.js";
import { getAllList } from "@/api/addressList/index.js";
import assetsItem from "./components/assetsItem.vue";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      list: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        isSelf: 0, //是否查询自己的固定资产0-否1-是
        departmentCode: "",
        principalCode: "",
      },
      departmentName: "公司/部门",
      principalName: "负责人",
      showDepartment: false,
      showPrincipal: false,
      departmentList: [],
      staffData: [],
      loading: false,
      finished: false,
      total: 0,
    };
  },
  name: "assetsList",
  components: { assetsItem ,TopWrapper},
  computed: {},
  filters: {},
  mounted() {
    this.getResourceList();
    this.getAllStaff();
    this.getList();
  },

  methods: {
    //获取部门
    getResourceList() {
      getAllList().then((res) => {
        let { code, data } = res;
        if (code == 200) {
          this.departmentList = [];
          this.departmentList = data.map((e) => {
            return {
              text: e.departmentName,
              value: e.departmentCode,
            };
          });
        }
      });
    },
    //员工
    getAllStaff() {
      getAllStaffList().then((res) => {
        let { data, code } = res;
        if (code == 200) {
          this.staffData = data.map((e) => {
            return {
              text: e.userName,
              value: e.staffCode,
            };
          });
        }
      });
    },
    getList() {
      queryFixedAssetsList({
        ...this.queryParams,
      })
        .then((res) => {
          if (this.queryParams.pageNum == 1) {
            this.list = res.data.records;
          } else {
            this.list = this.list.concat(res.data.records);
          }

          this.queryParams.pageNum++;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goDetail(item) {
      this.$router.push({
        path: "/assetsManDetail",
        query: {
          bizCode: item.bizCode,
        },
      });
    },
    handleLoad() {
      this.getList();
    },
    addAssets() {
      this.$router.push({
        path: "/addAssetsMan",
      });
    },
    //负责人
    staffConfirm(e) {
      this.showPrincipal = false;
      this.principalName = e.text;
      this.queryParams.principalCode = e.value;
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 部门
    departmentConfirm(e) {
      this.showDepartment = false;
      this.departmentName = e.text;
      this.queryParams.departmentCode = e.value;
      this.queryParams.pageNum = 1;
      this.getList();
    },
    clearOption() {
      this.departmentName = "公司/部门";
      this.queryParams.departmentCode = "";
      this.principalName = "负责人";
      this.queryParams.principalCode = "";
      this.queryParams.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.approvalList {
  min-height: 100%;
  background: #fafafa;
  // padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  .top {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-box {
      width: 5.92rem;
      height: 0.6rem;
      background: #ffffff;
      border-radius: 0.3rem;
      border: 1px solid #999999;
      display: flex;
      align-items: center;
      .search-icon {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.18rem;
        margin-right: 0.08rem;
      }
      .search-input {
        flex: 1;
        height: 0.4rem;
        background: #ffffff;
        border: none;
        width: 2rem;
      }
      .close-icon {
        height: 0.24rem;
        margin-right: 0.18rem;
      }
    }
    .filter {
      padding: 0 0.1rem;
      .filter-icon {
        widows: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
      }
      .text {
        height: 0.3rem;
        font-size: 0.28rem;
        font-weight: 500;
        color: #666666;
        line-height: 0.3rem;
      }
    }
  }
  .approval-ul {
    margin-top: 0.2rem;
  }
  .search-tab {
    width: 100%;
    height: 0.86rem;
    padding: 0.26rem 0.4rem;
    background: #fff;
    display: flex;
    flex-direction: row;
    .tab-cont {
      width: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.2rem;
      .label {
        font-size: 0.28rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.4rem;
        margin-right: 0.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .react {
        border: 0.1rem solid;
        border-color: #000000 transparent transparent transparent;
      }
    }
    .tab-cont-icon {
      width: 2.2rem;
      .label {
        font-size: 0.28rem;
        font-weight: 400;
        color: #0094ee;
        line-height: 0.4rem;
        margin-right: 0.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
      }
    }
  }
  .num-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.2rem;
    .num {
      font-size: 0.26rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.37rem;
    }
    .add {
      width: 1.28rem;
      height: 0.6rem;
      background: #0094ee;
      border-radius: 0.5rem;
      color: #fff;
      text-align: center;
      line-height: 0.6rem;
    }
  }
}
</style>
